<template>
  <div v-frag>
    <page-title title="order.orderPlaceNewOrder"></page-title>
    <new-order :service="serviceName"></new-order>
  </div>
</template>

<script>
import NewOrder from '../../components/orders/NewOrder.vue';
import PageTitle from '../../components/titles/PageTitle.vue';

export default {
  name: 'InnerNewOrderForm',
  beforeMount() {
    if (this.$route.params.szolgaltatas) {
      this.serviceName = this.$route.params.szolgaltatas;
    } else {
      //
    }
  },
  props: {
    //
  },
  components: {
    NewOrder, PageTitle,
  },
  data() {
    return {
      serviceName: '',
    };
  },
  methods: {
    //
  },
};
</script>

<style scoped>

</style>
