<template>
  <div v-frag>
    <v-container class="my-10">
      <v-card>
        <v-stepper v-model="VStepper" alt-labels>
          <!-- STEPPER HEADER -->
          <v-stepper-header>
            <template v-for="(item, index) in VStepperHeader">
              <v-stepper-step :key="index"
                              :step="item.step" :complete="VStepper > index+1">
                {{ $t(`${item.title}`) }}
              </v-stepper-step>
              <v-divider v-if="index !== VStepperHeader.length-1"></v-divider>
            </template>
          </v-stepper-header>
          <v-stepper-items>
            <!-- ÁLTALÁNOS INFORMÁCIÓK START -->
            <v-stepper-content step="1">
              <v-row style="max-height: 600px; overflow-y: scroll;">
                <v-col cols="12">
                  <div v-html="$t('tmxGeneralInformation')" ></div>
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                  <v-checkbox v-model="serviceRequestForm.generalInfos"
                              :label="$t('acceptCheckbox.generalInformation')"
                              :rules="inputRules.acceptInformation"
                  >
                  </v-checkbox>
                </v-col>
              </v-row>
              <v-row justify="end" class="mx-0 mt-5">
                <v-btn class="primary"
                       :disabled="!serviceRequestForm.generalInfos"
                       @click="nextStep">
                  {{ $t('button.next') }}
                </v-btn>
              </v-row>
            </v-stepper-content>
            <!-- ÁLTALÁNOS INFORMÁCIÓK END -->
            <!-- KÉSZÜLÉK ADATOK START -->
            <v-stepper-content step="2">
              <v-form v-model="deviceFormIsValid">
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="serviceRequestForm.serviceRequest.insuranceNumber"
                             :label="sourceProp === 'mostmobil' ? $t('requestIdOrWorksheetNumber') : $t('damageNumber')"
                             :rules="inputRules.generalRules"
                             :hint="$t('inputMessages.requiredField')"
                             persistent-hint
                             filled>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-file-input v-model="attachment.invoice"
                                  :label="$t('invoice')"
                                  :hint="$t('inputMessages.optionalField')"
                                  persistent-hint
                                  accept=".pdf"
                                  counter
                                  show-size
                                  filled
                                  prepend-icon=""
                                  append-icon="mdi-paperclip">
                      <template v-slot:selection="{text}">
                        <v-chip small label color="primary">
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-file-input v-model="attachment.warrantyCard"
                                  :label="$t('warrantyTicket')"
                                  :hint="$t('inputMessages.optionalField')"
                                  persistent-hint
                                  accept=".pdf"
                                  counter
                                  show-size
                                  filled
                                  prepend-icon=""
                                  append-icon="mdi-paperclip">
                      <template v-slot:selection="{text}">
                        <v-chip small label color="primary">
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-autocomplete v-model="serviceRequestForm.serviceRequest.brand"
                                    :items="selectFieldOptions.brands"
                                    :label="$t('brand')"
                                    item-value="id"
                                    item-text="name"
                                    :rules="inputRules.generalRules"
                                    :hint="$t('inputMessages.requiredField')"
                                    persistent-hint
                                    filled
                                    return-object
                                    required>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-select v-model="serviceRequestForm.serviceRequest.productGroup"
                              :items="selectFieldOptions.productGroups"
                              item-text="name"
                              item-value="id"
                              :no-data-text="$t('noDataText.productGroups')"
                              :label="$t('inputLabels.productGroup')"
                              :rules="inputRules.generalRules"
                              :hint="$t('inputMessages.requiredField')"
                              persistent-hint
                              filled
                              return-object
                              required>
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="serviceRequestForm.serviceRequest.productType"
                                  :label="$t('productType')"
                                  :rules="inputRules.generalRules"
                                  :hint="$t('inputMessages.requiredField')"
                                  persistent-hint
                                  filled>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" v-if="selectedProductGroup && selectedProductGroup.isUniqueIdentifier1">
                    <v-text-field v-model="serviceRequestForm.serviceRequest.uniqueIdentifierIn1"
                                  :label="selectedProductGroup.nameUniqueIdentifier1"
                                  :messages="$t(`${imeiHint}`)"
                                  :hint="$t('imeiHint')"
                                  counter="15"
                                  maxlength="15"
                                  persistent-hint
                                  filled>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" v-if="selectedProductGroup && selectedProductGroup.isUniqueIdentifier2">
                    <v-text-field v-model="serviceRequestForm.serviceRequest.uniqueIdentifierIn2"
                                  :label="selectedProductGroup.nameUniqueIdentifier2"
                                  :hint="$t('inputMessages.optionalField')"
                                  counter="15"
                                  maxlength="15"
                                  persistent-hint
                                  filled>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" v-if="selectedProductGroup && selectedProductGroup.isUniqueIdentifier3">
                    <v-text-field v-model="serviceRequestForm.serviceRequest.uniqueIdentifierIn3"
                                  :label="selectedProductGroup.nameUniqueIdentifier3"
                                  :hint="$t('inputMessages.optionalField')"
                                  counter="15"
                                  maxlength="15"
                                  persistent-hint
                                  filled>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-dialog
                        ref="dialog"
                        v-model="datePickerModal"
                        :return-value.sync="serviceRequestForm.serviceRequest.dateOfPurchase"
                        persistent
                        width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                            v-model="serviceRequestForm.serviceRequest.dateOfPurchase"
                            :label="$t('dateOfPurchase')"
                            :hint="$t('inputMessages.optionalField')"
                            persistent-hint
                            filled
                            readonly
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="serviceRequestForm.serviceRequest.dateOfPurchase"
                                     :first-day-of-week="1"
                                     :locale="$i18n.locale"
                                     :max="maxDate"
                                     min="2010-01-01" @input="$refs.dialog.save(serviceRequestForm.serviceRequest.dateOfPurchase)">
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="modal = false">{{ $t('button.cancel') }}</v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <!--<v-col cols="12">
                    <v-row>
                      &lt;!&ndash;<v-col cols="12" sm="6">
                        <v-select v-model="serviceRequestForm.serviceRequest.simLock"
                                  :items="$i18n.locale === 'hu-HU' ? selectFieldOptions.simLock : $i18n.locale === 'de-DE' ? selectFieldOptions.simLockDE : selectFieldOptions.simLockEN"
                                  item-text="name"
                                  item-value="id"
                                  :label="$t('inputLabels.simLock')"
                                  :rules="inputRules.generalRules"
                                  :hint="$t('inputMessages.requiredField')"
                                  persistent-hint
                                  disabled
                                  filled
                                  return-object
                                  required>
                        </v-select>
                      </v-col>&ndash;&gt;
                      <v-col cols="12" sm="6">
                        <v-dialog
                          ref="dialog"
                          v-model="datePickerModal"
                          :return-value.sync="serviceRequestForm.serviceRequest.dateOfPurchase"
                          persistent
                          width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="serviceRequestForm.serviceRequest.dateOfPurchase"
                              :label="$t('dateOfPurchase')"
                              :hint="$t('inputMessages.optionalField')"
                              persistent-hint
                              filled
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="serviceRequestForm.serviceRequest.dateOfPurchase"
                                         :first-day-of-week="1"
                                         :locale="$i18n.locale"
                                         :max="maxDate"
                                         min="2010-01-01" @input="$refs.dialog.save(serviceRequestForm.serviceRequest.dateOfPurchase)">
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="modal = false">{{ $t('button.cancel') }}</v-btn>
                          </v-date-picker>
                        </v-dialog>
                      </v-col>
                      &lt;!&ndash; <v-col cols="12" sm="6">
                        <v-file-input v-model="attachment.evidence"
                                      :label="$t('evidence')"
                                      :hint="$t('inputMessages.optionalField')"
                                      persistent-hint
                                      accept=".pdf"
                                      counter
                                      show-size
                                      filled
                                      prepend-icon=""
                                      append-icon="mdi-paperclip">
                          <template v-slot:selection="{text}">
                            <v-chip small label color="primary">
                              {{ text }}
                            </v-chip>
                          </template>
                        </v-file-input>
                      </v-col> &ndash;&gt;
                    </v-row>
                  </v-col>-->
                  <v-col cols="12">
                    <v-textarea v-model="serviceRequestForm.serviceRequest.errorDescription"
                                :label="$t('troubleDescription')"
                                :hint="$t('inputMessages.optionalField')"
                                persistent-hint
                                clearable
                                filled
                                clear-icon="mdi-close">
                    </v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <v-row justify="end" class="mx-0">
                      <v-btn color="primary"
                             text
                             @click="prevStep">
                        {{ $t('button.back') }}
                      </v-btn>
                      <v-btn class="primary"
                             :disabled="!deviceFormIsValid"
                             @click="nextStep">
                        {{ $t('button.next') }}
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-row>
              </v-form>
            </v-stepper-content>
            <!-- KÉSZÜLÉK ADATOK END -->
            <!-- MEGRENDELŐ ADATOK START -->
            <v-stepper-content step="3">
              <v-form v-model="customerFormIsValid">
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field v-model="serviceRequestForm.customer.lastName"
                                  :label="$t('lastName')"
                                  :rules="inputRules.generalRules"
                                  :hint="$t('inputMessages.requiredField')"
                                  persistent-hint
                                  filled>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field v-model="serviceRequestForm.customer.firstName"
                                  :label="$t('firstName')"
                                  :rules="inputRules.generalRules"
                                  :hint="$t('inputMessages.requiredField')"
                                  persistent-hint
                                  filled>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field v-model="serviceRequestForm.customer.email"
                                  :label="$t('email')"
                                  :rules="inputRules.emailRules"
                                  :hint="$t('inputMessages.requiredField')"
                                  persistent-hint
                                  filled>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field v-model="serviceRequestForm.customer.phoneNumber"
                                  :label="$t('phoneNumber')"
                                  :rules="inputRules.phoneRules"
                                  :hint="$t('inputMessages.requiredField')"
                                  persistent-hint
                                  prefix="+36-"
                                  v-mask="mask"
                                  filled>
                    </v-text-field>
                  </v-col>
                  <!-- CÍM ADATOK START -->
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-text-field v-model="deliveryInAddressString"
                                      :label="$t('shippingAddress')"
                                      :rules="inputRules.generalRules"
                                      :hint="$t('inputMessages.requiredField')"
                                      persistent-hint
                                      filled
                                      append-icon="mdi-pencil"
                                      readonly
                                      @click="deliveryInAddressModal = true"
                                      @click:append="deliveryInAddressModal = true">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field v-model="billingAddressString"
                                      :label="$t('invoicingAddress')"
                                      :rules="inputRules.generalRules"
                                      :hint="$t('inputMessages.requiredField')"
                                      persistent-hint
                                      filled
                                      append-icon="mdi-pencil"
                                      readonly
                                      :disabled="billingAddressIsEqualdeliveryInAddress"
                                      @click="billingAddressModal = true"
                                      @click:append="billingAddressModal = true">
                        </v-text-field>
                        <v-checkbox v-model="billingAddressIsEqualdeliveryInAddress"
                                    class="mt-0" :disabled="!(serviceRequestForm.customer.lastName.length > 1 && deliveryInAddressForm)">
                          <template slot="label">
                            <span class="body-2">
                              {{ $t('invoicingAddressIsEqualShippingAddress') }}
                            </span>
                          </template>
                        </v-checkbox>
                      </v-col>
                    </v-row>
                  </v-col>
                  <!-- CÍM ADATOK END -->
                  <!-- REGISZTRÁCIÓ START -->
                  <v-col cols="12" v-if="!$store.state.authModule.isAuthenticated">
                    <v-card class="fill-height">
                      <v-card-title>
                        <v-icon color="primary" class="mr-4">mdi-shield-account</v-icon>
                        <span class="subtitle-1">{{ $t('accountRegistration') }}</span>
                      </v-card-title>
                      <v-divider></v-divider>
                      <v-card-text :class="$vuetify.breakpoint.xsOnly ? 'pa-3' : 'pa-6'">
                        <v-row>
                          <v-col cols="12" class="body-1 pb-0">
                            {{ $t('accountRegistrationQuestionOnForm') }}
                          </v-col>
                          <v-col cols="12" class="pt-0">
                            <v-radio-group v-model="serviceRequestForm.accountRegistration"
                                           :rules="inputRules.generalRules"
                                           :hint="$t('inputMessages.requiredField')"
                                           persistent-hint>
                              <v-radio value="yes" :label="$t('inputLabels.accountRegistrationTrue')"></v-radio>
                              <v-radio value="no" :label="$t('inputLabels.accountRegistrationFalse')"></v-radio>
                            </v-radio-group>
                          </v-col>
                        </v-row>
                        <v-expand-transition>
                          <v-row v-show="serviceRequestForm.accountRegistration === 'yes'">
                            <v-col cols="12">
                              <p class="body-1">
                                Kérjük a lenti mezőben adj meg egy jelszót. Ezzel tudsz majd bejelentkezni a felhasználói fiókodba. A bejelentkezési e-mail cím az Ügyfél adatoknál megadott (<b>{{ serviceRequestForm.customer.email }}</b>) e-mail címed lesz.
                              </p>
                              <p>
                                A jelszónak kötelezően tartalmaznia kell minimum 1db kis-, 1 db nagybetűt és minimum 1 db számot. A jelszó hossza minimum 8 karakter kell hogy legyen!
                              </p>
                            </v-col>
                            <v-col cols="12" sm="6" offset-sm="3">
                              <v-text-field v-model="serviceRequestForm.customer.password"
                                            :label="$t('inputLabels.password')"
                                            :rules="serviceRequestForm.accountRegistration === 'yes' ? inputRules.min8 : []"
                                            :hint="serviceRequestForm.accountRegistration === 'yes' ? $t('inputMessages.requiredField') : ''"
                                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                            :type="show1 ? 'text' : 'password'"
                                            persistent-hint
                                            counter="30"
                                            filled
                                            required
                                            @click:append="show1 = !show1">
                              </v-text-field>
                            </v-col>
                          </v-row>
                        </v-expand-transition>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <!-- REGISZTRÁCIÓ END -->
                  <v-col cols="12">
                    <v-row justify="end" class="mx-0">
                      <v-btn color="primary"
                             text
                             @click="prevStep">
                        {{ $t('button.back') }}
                      </v-btn>
                      <v-btn class="primary"
                             :disabled="!customerFormIsValid"
                             @click="nextStep">
                        {{ $t('button.next') }}
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-row>
              </v-form>
            </v-stepper-content>
            <!-- MEGRENDELŐ ADATOK END -->
            <!-- VÉGLEGESÍTÉS START -->
            <v-stepper-content step="4">
              <!-- KÉSZÜLÉK ADATOK ELLENŐRZÉS START -->
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field v-model="serviceRequestForm.serviceRequest.insuranceNumber"
                                :label="sourceProp === 'mostmobil' ? $t('requestIdOrWorksheetNumber') : $t('damageNumber')"
                                disabled
                                filled>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-file-input v-model="attachment.invoice"
                                :label="$t('invoice')"
                                disabled
                                persistent-hint
                                accept=".pdf"
                                counter
                                show-size
                                filled
                                prepend-icon=""
                                append-icon="mdi-paperclip">
                    <template v-slot:selection="{text}">
                      <v-chip small label color="grey lighten-1">
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-file-input v-model="attachment.warrantyCard"
                                :label="$t('warrantyTicket')"
                                disabled
                                persistent-hint
                                accept=".pdf"
                                counter
                                show-size
                                filled
                                prepend-icon=""
                                append-icon="mdi-paperclip">
                    <template v-slot:selection="{text}">
                      <v-chip small label color="grey lighten-1">
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-autocomplete v-model="serviceRequestForm.serviceRequest.brand"
                                  :items="selectFieldOptions.brands"
                                  :label="$t('brand')"
                                  item-value="id"
                                  item-text="name"
                                  disabled
                                  persistent-hint
                                  filled
                                  return-object
                                  required>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-select v-model="serviceRequestForm.serviceRequest.productGroup"
                            :items="selectFieldOptions.productGroups"
                            item-text="name"
                            item-value="id"
                            :label="$t('inputLabels.productGroup')"
                            disabled
                            persistent-hint
                            filled
                            return-object
                            required>
                  </v-select>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field v-model="serviceRequestForm.serviceRequest.productType"
                                :label="$t('productType')"
                                disabled
                                persistent-hint
                                filled>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" v-if="selectedProductGroup && selectedProductGroup.isUniqueIdentifier1">
                  <v-text-field v-model="serviceRequestForm.serviceRequest.uniqueIdentifierIn1"
                                :label="selectedProductGroup.nameUniqueIdentifier1"
                                :messages="$t(`${imeiHint}`)"
                                disabled
                                counter="15"
                                maxlength="15"
                                persistent-hint
                                filled>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" v-if="selectedProductGroup && selectedProductGroup.isUniqueIdentifier2">
                  <v-text-field v-model="serviceRequestForm.serviceRequest.uniqueIdentifierIn2"
                                :label="selectedProductGroup.nameUniqueIdentifier2"
                                disabled
                                counter="15"
                                maxlength="15"
                                persistent-hint
                                filled>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" v-if="selectedProductGroup && selectedProductGroup.isUniqueIdentifier3">
                  <v-text-field v-model="serviceRequestForm.serviceRequest.uniqueIdentifierIn3"
                                :label="selectedProductGroup.nameUniqueIdentifier3"
                                disabled
                                counter="15"
                                maxlength="15"
                                persistent-hint
                                filled>
                  </v-text-field>
                </v-col>
                <!--<v-col cols="12" sm="6" md="4">
                  <v-select v-model="serviceRequestForm.serviceRequest.simLock"
                            :items="$i18n.locale === 'hu-HU' ? selectFieldOptions.simLock : $i18n.locale === 'de-DE' ? selectFieldOptions.simLockDE : selectFieldOptions.simLockEN"
                            item-text="name"
                            item-value="id"
                            :label="$t('inputLabels.simLock')"
                            disabled
                            persistent-hint
                            filled
                            return-object
                            required>
                  </v-select>
                </v-col>-->
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-dialog
                        ref="dialog"
                        v-model="datePickerModal"
                        :return-value.sync="serviceRequestForm.serviceRequest.dateOfPurchase"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="serviceRequestForm.serviceRequest.dateOfPurchase"
                            :label="$t('dateOfPurchase')"
                            disabled
                            persistent-hint
                            filled
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="serviceRequestForm.serviceRequest.dateOfPurchase"
                                       :first-day-of-week="1"
                                       :locale="$i18n.locale"
                                       :max="maxDate"
                                       min="2010-01-01" @input="$refs.dialog.save(serviceRequestForm.serviceRequest.dateOfPurchase)">
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="modal = false">{{ $t('button.cancel') }}</v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                    <!-- <v-col cols="12" sm="6" md="4">
                      <v-file-input v-model="attachment.evidence"
                                    :label="$t('evidence')"
                                    disabled
                                    persistent-hint
                                    accept=".pdf"
                                    counter
                                    show-size
                                    filled
                                    prepend-icon=""
                                    append-icon="mdi-paperclip">
                        <template v-slot:selection="{text}">
                          <v-chip small label color="primary">
                            {{ text }}
                          </v-chip>
                        </template>
                      </v-file-input>
                    </v-col> -->
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-textarea v-model="serviceRequestForm.serviceRequest.errorDescription"
                              :label="$t('troubleDescription')"
                              disabled
                              persistent-hint
                              clearable
                              filled
                              clear-icon="mdi-close">
                  </v-textarea>
                </v-col>
              </v-row>
              <!-- KÉSZÜLÉK ADATOK ELLENŐRZÉS END -->
              <v-row>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <!-- MEGRENDELŐ ADATOK ELLENŐRZÉS START -->
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="serviceRequestForm.customer.lastName"
                                :label="$t('lastName')"
                                disabled
                                persistent-hint
                                filled>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="serviceRequestForm.customer.firstName"
                                :label="$t('firstName')"
                                disabled
                                persistent-hint
                                filled>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="serviceRequestForm.customer.email"
                                :label="$t('email')"
                                disabled
                                persistent-hint
                                filled>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="serviceRequestForm.customer.phoneNumber"
                                :label="$t('phoneNumber')"
                                disabled
                                persistent-hint
                                prefix="+36-"
                                v-mask="mask"
                                filled>
                  </v-text-field>
                </v-col>
                <!-- CÍM ADATOK START -->
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field v-model="deliveryInAddressString"
                                    :label="$t('shippingAddress')"
                                    disabled
                                    persistent-hint
                                    filled
                                    readonly>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field v-model="billingAddressString"
                                    :label="$t('invoicingAddress')"
                                    disabled
                                    persistent-hint
                                    filled
                                    readonly>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <!-- CÍM ADATOK END -->
              </v-row>
              <!-- MEGRENDELŐ ADATOK ELLENŐRZÉS END -->
              <!-- ACTION BUTTONS START -->
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-checkbox
                    v-model="serviceRequestForm.gdpr"
                    :rules="inputRules.gdprRules"
                    required
                    class="ma-0"
                  >
                    <template slot="label">
                      <span v-show="$i18n.locale === 'hu-HU'" class="body-2">Az <a :href="`${publicPath}doc/Adatvedelmi_Tajekoztato_Honlap_Latogatoknak.pdf`" target="_blank" @click.stop>Adatvédelmi tájékoztatót</a>&nbsp;elolvastam és elfogadom.</span>
                      <span v-show="$i18n.locale === 'en-EN'" class="body-2">I have read and accepted the&nbsp;<a :href="`${publicPath}doc/Adatvedelmi_Tajekoztato_Honlap_Latogatoknak.pdf`" target="_blank" @click.stop>Privacy Policy</a>.</span>
                      <span v-show="$i18n.locale === 'de-DE'" class="body-2">Ich habe die&nbsp;<a :href="`${publicPath}doc/Adatvedelmi_Tajekoztato_Honlap_Latogatoknak.pdf`" target="_blank" @click.stop>Datenschutzbestimmungen</a> gelesen und akzeptiere sie.</span>
                    </template>
                  </v-checkbox>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-checkbox
                    v-model="serviceRequestForm.termsAndCond"
                    :rules="inputRules.gdprRules"
                    required
                    class="ma-0"
                  >
                    <template slot="label">
                            <span v-show="$i18n.locale === 'hu-HU'" class="body-2">
                              Az&nbsp;<a :href="`${publicPath}doc/TMX_Terms_and_Conditions_hu.pdf`" target="_blank" @click.stop>Általános Szerződési Feltételeket</a>&nbsp;elolvastam és elfogadom.
                            </span>
                      <span v-show="$i18n.locale === 'en-EN'" class="body-2">
                              I have read and accepted the&nbsp;<a :href="`${publicPath}doc/TMX_Terms_and_Conditions_en.pdf`" target="_blank" @click.stop>Terms and Conditions</a>.
                            </span>
                      <span v-show="$i18n.locale === 'de-DE'" class="body-2">
                              Ich habe die&nbsp;<a :href="`${publicPath}doc/TMX_Terms_and_Conditions_de.pdf`" target="_blank" @click.stop>AGB</a> gelesen und akzeptiere sie.
                            </span>
                    </template>
                  </v-checkbox>
                </v-col>
                <v-col cols="12">
                  <v-row justify="end" class="mx-0">
                    <v-btn color="primary"
                           text
                           @click="prevStep">
                      {{ $t('button.back') }}
                    </v-btn>
                    <v-btn class="primary" :disabled="!(serviceRequestForm.termsAndCond && serviceRequestForm.gdpr)" @click="sendContactForm">
                      {{ $t('button.send') }}
                    </v-btn>
                  </v-row>
                </v-col>
              </v-row>
              <!-- ACTION BUTTONS END -->
            </v-stepper-content>
            <!-- VÉGLEGESÍTÉS END -->
            <!-- KÉSZ START -->
            <v-stepper-content step="5">
              <v-row>
                <v-col cols="12" class="pt-12">
                  <div v-html="responseDialog.response.message"></div>
                </v-col>
              </v-row>
            </v-stepper-content>
            <!-- KÉSZ END -->
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-container>
    <!-- SZÁLLÍTÁSI CÍM MODAL -->
    <v-dialog v-model="deliveryInAddressModal" max-width="800" persistent>
      <v-card tile>
        <v-card-title class="third">
          <v-icon color="white" class="mr-4">mdi-truck-check-outline</v-icon>
          <span class="white--text">{{ $t('shippingAddress') }}</span>
          <v-spacer></v-spacer>
          <v-icon @click="deliveryInAddressModal = false" color="white">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-stepper v-model="shippingAddressStepper">
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-select v-model="customerShippingAddress"
                        :items="selectFieldOptions.customerShippingAddresses"
                        item-text="label"
                        item-value="id"
                        :label="$t('inputLabels.selectShippingAddress')"
                        :rules="inputRules.generalRules"
                        :hint="$t('inputMessages.requiredField')"
                        persistent-hint
                        filled
                        return-object
                        required>
              </v-select>
            </v-stepper-content>
            <v-stepper-content step="2" class="pa-0">
              <v-card-text class="pa-6">
                <v-form v-model="deliveryInAddressForm">
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-autocomplete v-model="serviceRequestForm.deliveryInAddress.postalCode"
                                      :items="selectFieldOptions.postalCodes"
                                      item-text="address.postalCode"
                                      item-value="address.postalCode"
                                      :search-input.sync="postalCodeSearch"
                                      :label="$t('inputLabels.postalCode')"
                                      :rules="inputRules.phoneRules"
                                      :hint="$t('inputMessages.requiredField')"
                                      :no-data-text="$t('inputMessages.noDataText')"
                                      persistent-hint
                                      filled
                                      maxlength="4"
                                      required
                                      @change="onPostalCodeSelect"
                                      @click:clear="clearAllAddressInputs"
                                      clearable>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-autocomplete v-model="serviceRequestForm.deliveryInAddress.city"
                                      :items="selectFieldOptions.cities"
                                      item-value="address.city"
                                      item-text="address.city"
                                      :search-input.sync="citySearch"
                                      :label="$t('inputLabels.city')"
                                      :rules="inputRules.generalRules"
                                      :hint="$t('inputMessages.requiredField')"
                                      :no-data-text="$t('inputMessages.noDataText')"
                                      persistent-hint
                                      filled
                                      required
                                      clearable
                                      @click:clear="clearAllAddressInputs">
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="8">
                      <v-combobox v-model="streetIdeiglenes"
                                  :items="selectFieldOptions.streets"
                                  item-value="address.street"
                                  item-text="address.street"
                                  :search-input.sync="streetSearch"
                                  :label="$t('inputLabels.publicPlaceName')"
                                  :rules="inputRules.generalRules"
                                  :hint="$t('inputMessages.requiredField')"
                                  :no-data-text="$t('inputMessages.noDataText')"
                                  persistent-hint
                                  filled
                                  required
                                  @change="onPublicPlaceSelect"
                                  clearable>
                      </v-combobox>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field v-model="serviceRequestForm.deliveryInAddress.streetNumber"
                                    :label="$t('inputLabels.streetNumber')"
                                    :rules="inputRules.generalRules"
                                    :hint="$t('inputMessages.requiredField')"
                                    :no-data-text="$t('inputMessages.noDataText')"
                                    persistent-hint
                                    filled
                                    required
                                    clearable>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea v-model="serviceRequestForm.deliveryInAddress.other"
                                  :label="$t('infoToDeliver')"
                                  :rules="inputRules.noSpecialChar"
                                  :hint="$t('inputMessages.optionalField')"
                                  persistent-hint
                                  clearable
                                  filled
                                  clear-icon="mdi-close"
                                  @keydown.prevent.enter="preventEnter"
                                  maxlength="50"
                                  counter="50">
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions class="px-6 pb-6">
                <v-spacer></v-spacer>
                <v-btn text @click="deliveryInAddressModal = false">
                  {{ $t('button.cancel') }}
                </v-btn>
                <v-btn class="primary" @click="saveDeliveryInAddressData" :disabled="!deliveryInAddressForm">
                  {{ $t('button.save') }}
                </v-btn>
              </v-card-actions>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
    <!-- SZÁMLÁZÁSI CÍM MODAL -->
    <v-dialog v-model="billingAddressModal" max-width="800" persistent>
      <v-card tile>
        <v-card-title class="third">
          <v-icon color="white" class="mr-4">mdi-file-account-outline</v-icon>
          <span class="white--text">{{ $t('invoicingAddress') }}</span>
          <v-spacer></v-spacer>
          <v-icon @click="billingAddressModal = false" color="white">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-stepper v-model="billingAddressStepper">
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-select v-model="customerBillingAddress"
                        :items="selectFieldOptions.customerBillingAddresses"
                        item-text="label"
                        item-value="id"
                        :label="$t('inputLabels.selectBillingAddress')"
                        :rules="inputRules.generalRules"
                        :hint="$t('inputMessages.requiredField')"
                        persistent-hint
                        filled
                        return-object
                        required>
              </v-select>
            </v-stepper-content>
            <v-stepper-content step="2" class="px-0">
              <v-card-text class="pa-6">
                <v-form v-model="billingAddressForm">
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field v-model="serviceRequestForm.billingAddress.billingName"
                                    :label="$t('invoicingName')"
                                    :rules="inputRules.required"
                                    :hint="$t('inputMessages.requiredField')"
                                    persistent-hint
                                    filled>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field v-model="serviceRequestForm.billingAddress.taxNumber"
                                    :label="$t('vatNumber')"
                                    :hint="$t('inputMessages.optionalField')"
                                    persistent-hint
                                    filled>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-autocomplete v-model="serviceRequestForm.billingAddress.postalCode"
                                      :items="selectFieldOptions.postalCodesInvoice"
                                      item-text="address.postalCode"
                                      item-value="address.postalCode"
                                      :search-input.sync="postalCodeSearchInvoice"
                                      :label="$t('inputLabels.postalCode')"
                                      :rules="inputRules.phoneRules"
                                      :hint="$t('inputMessages.requiredField')"
                                      :no-data-text="$t('inputMessages.noDataText')"
                                      persistent-hint
                                      filled
                                      maxlength="4"
                                      required
                                      @change="onPostalCodeSelectInvoice"
                                      @click:clear="clearAllAddressInputsInvoice"
                                      clearable>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-autocomplete v-model="serviceRequestForm.billingAddress.city"
                                      :items="selectFieldOptions.citiesInvoice"
                                      item-value="address.city"
                                      item-text="address.city"
                                      :search-input.sync="citySearchInvoice"
                                      :label="$t('inputLabels.city')"
                                      :rules="inputRules.generalRules"
                                      :hint="$t('inputMessages.requiredField')"
                                      :no-data-text="$t('inputMessages.noDataText')"
                                      persistent-hint
                                      filled
                                      required
                                      clearable
                                      @click:clear="clearAllAddressInputsInvoice">
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="8">
                      <v-combobox v-model="streetIdeiglenesInvoice"
                                  :items="selectFieldOptions.streetsInvoice"
                                  item-value="address.street"
                                  item-text="address.street"
                                  :search-input.sync="streetSearchInvoice"
                                  :label="$t('inputLabels.publicPlaceName')"
                                  :rules="inputRules.generalRules"
                                  :hint="$t('inputMessages.requiredField')"
                                  :no-data-text="$t('inputMessages.noDataText')"
                                  persistent-hint
                                  filled
                                  required
                                  @change="onPublicPlaceSelectInvoice"
                                  clearable>
                      </v-combobox>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field v-model="serviceRequestForm.billingAddress.streetNumber"
                                    :label="$t('inputLabels.streetNumber')"
                                    :rules="inputRules.generalRules"
                                    :hint="$t('inputMessages.requiredField')"
                                    :no-data-text="$t('inputMessages.noDataText')"
                                    persistent-hint
                                    filled
                                    required
                                    clearable>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea v-model="serviceRequestForm.billingAddress.other"
                                  :label="$t('infoToDeliver')"
                                  :rules="inputRules.noSpecialChar"
                                  :hint="$t('inputMessages.optionalField')"
                                  persistent-hint
                                  clearable
                                  filled
                                  clear-icon="mdi-close"
                                  maxlength="50"
                                  counter="50">
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions class="px-6 pb-6">
                <v-spacer></v-spacer>
                <v-btn text @click="billingAddressModal = false">
                  {{ $t('button.cancel') }}
                </v-btn>
                <v-btn class="primary" @click="saveBillingAddressData" :disabled="!billingAddressForm">
                  {{ $t('button.save') }}
                </v-btn>
              </v-card-actions>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
    <!-- RESPONSE DIALOGS START -->
    <v-dialog v-model="loadingDialog.visible" width="90%" max-width="340" :persistent="true">
      <loading-dialog v-bind:loading-message="loadingDialog.message" />
    </v-dialog>
    <v-dialog v-model="responseDialog.visible" width="90%" max-width="340" :persistent="true">
      <response-dialog v-bind:response-object="responseDialog.response"
                       v-on:close-dialog="closeResponseDialog"/>
    </v-dialog>
    <v-dialog v-model="noFileDialog.visible" width="90%" max-width="340" :persistent="true">
      <response-dialog v-bind:response-object="noFileDialog.response"
                       v-on:close-dialog="closeNoFileDialog"/>
    </v-dialog>
    <!-- RESPONSE DIALOGS END -->
  </div>
</template>

<script>
// import { LUHN_CHECK } from "../../store/actions.type";
import dayjs from "dayjs";
import LoadingDialog from "../dialogs/LoadingDialog";
import ResponseDialog from "../dialogs/ResponseDialog";
import {
  HEREGETPOSTALCODESBYCOUNTRY,
  HEREGETCITYBYPOSTALCODE,
  HEREGETSTREETBYPOSTALCODE
} from "../../utils/hereAPI";
import { getToken, getUid } from '../../utils/jwtHelper';

export default {
  name: 'NewOrder',
  components: {
    LoadingDialog, ResponseDialog
  },
  props: {
    sourceProp: {
      type: String,
      default: 'general',
    }
  },
  async beforeMount() {
    this.setMaxAvailableDateOfPurchase();
    this.setDefaultCountry();
    if (await getToken()) {
      if (await getUid()) {
        await this.setCustomerDatas();
        await this.setCustomerAddresses();
        this.shippingAddressStepper = 1;
        this.billingAddressStepper = 1;
      }
    }
  },
  data() {
    return {
      VStepper: 1,
      VStepperHeader: [
        { step: 1, title: 'stepper.generalDetails', isDivider: false },
        { step: 2, title: 'stepper.deviceDetails', isDivider: false },
        { step: 3, title: 'stepper.customerDetails', isDivider: false },
        { step: 4, title: 'stepper.confirmation', isDivider: false },
        { step: 5, title: 'stepper.done', isDivider: false },
      ],
      acceptGeneralInfos: false,
      deviceFormIsValid: false,
      customerFormIsValid: false,
      // igény leadási form
      serviceRequestForm: {
        processType: 1,
        origCrmRequestId: null,
        origRequestSource: null,
        serviceRequest: {
          partnerWorksheetNumber: null,
          availableServiceId: 26,
          brand: {},
          productGroup: {},
          productType: '',
          uniqueIdentifierIn1: null,
          uniqueIdentifierIn2: null,
          uniqueIdentifierIn3: null,
          warrantyType: null,
          dateOfPurchase: null,
          errorDescription: '',
          simLock: {},
          mobilProvider: {},
          insuranceNumber: '',
          rmaNumber: '',
          accessories: null,
        },
        customer: {
          id: null,
          crmNamePrefixId: null,
          firstName: '',
          middleName: null,
          lastName: '',
          email: '',
          phoneNumber: '',
          password: '',
        },
        deliveryInAddress: {
          id: null,
          country: {},
          city: '',
          postalCode: '',
          publicPlaceName: '',
          publicPlaceType: '',
          streetNumber: '',
          other: null,
        },
        deliveryOutAddress: {},
        billingAddress: {
          id: null,
          country: {},
          city: '',
          postalCode: '',
          publicPlaceName: '',
          publicPlaceType: '',
          streetNumber: '',
          other: null,
          billingName: '',
          taxNumber: null,
          accountNumber: null,
        },
        gdpr: false,
        termsAndCond: false,
        generalInfos: false,
        accountRegistration: null,
      },
      attachment: {
        invoice: [],
        warrantyCard: [],
        evidence: [],
      },
      // szállítási cím
      deliveryInAddressString: '',
      deliveryInAddressForm: false,
      deliveryInAddressModal: false,
      countrySearch: '',
      countrySearchIsLoading: false,
      postalCodeSearch: '',
      postalCodeSearchIsLoading: false,
      citySearch: '',
      citySearchIsLoading: false,
      streetSearch: '',
      streetSearchIsLoading: false,
      streetIdeiglenes: '',
      deliveryInAddressBackup: null,
      // számlázási cím
      billingAddressString: '',
      billingAddressForm: false,
      billingAddressModal: false,
      postalCodeSearchInvoice: '',
      postalCodeSearchIsLoadingInvoice: false,
      citySearchInvoice: '',
      citySearchIsLoadingInvoice: false,
      streetSearchInvoice: '',
      streetSearchIsLoadingInvoice: false,
      streetIdeiglenesInvoice: '',
      billingAddressBackup: null,
      // számlázási cím megegyezik a szállítási címmel
      billingAddressIsEqualdeliveryInAddress: false,
      // user által megadott címek kezeléséhez
      customerShippingAddress: {},
      customerBillingAddress: {},
      shippingAddressStepper: 2,
      billingAddressStepper: 2,
      brand: {},
      selectedProductGroup: {},
      legalText: '',
      step: 1,
      show1: false,
      mask: '##-###-####',
      modal: false,
      contactFormIsValid: false,
      contactFormCheckbox: false,
      imeiHint: '',
      maxDate: null,
      loadingDialog: {
        visible: false,
        message: 'loadingMessages.INPROGRESS_CONTACT_FORM',
      },
      responseDialog: {
        visible: false,
        response: {
          error: false,
          data: [],
          message: 'successMessages.SUCCESS_REPAIR_FORM',
        },
      },
      noFileDialog: {
        visible: false,
        response: {
          error: false,
          data: [],
          message: 'errorMessages.ERROR_AT_LEAST_ONE_FILE',
        },
      },
      inputRules: {
        acceptInformation: [v => !!v || this.$t('inputMessages.acceptInformation')],
        generalRules: [v => !!v || this.$t('inputMessages.requiredField')],
        emailRules: [
          v => /.+@.+\..+/.test(v) || this.$t('inputMessages.emailMatch'),
          v => !(/\s/.test(v)) || this.$t('inputMessages.emailNoSpace'),
          v => !!v || this.$t('inputMessages.requiredField'),
        ],
        phoneRules: [
          v => /^[0-9\-]*$/.test(v) || this.$t('inputMessages.onlyNumbers'),
          v => !!v || this.$t('inputMessages.requiredField'),
        ],
        gdprRules: [
          v => !!v || this.$t('inputMessages.requiredPrivacy'),
        ],
        termAndCondRules: [
          v => !!v || this.$t('inputMessages.requiredTermsAndCond'),
        ],
        maxFileSize: [
          v => !v || v.size < 50000000 || this.$t('inputMessages.maxFileSizeRule'),
        ],
        requiredFile: [
          v => !v || v.size < 50000000 || this.$t('inputMessages.maxFileSizeRule'),
          v => !!v || this.$t('inputMessages.requiredField'),
        ],
        noSpecialChar: [
          (v) => {
            return /^[a-zA-Z0-9-. öÖüÜóÓőŐúÚéÉáÁűŰíÍ]*$/.test(v) || this.$t('inputMessages.noSpecialChar')
          },
        ],
      },
      selectFieldOptions: {
        brands: this.$store.state.selectfields.brandsNew,
        productGroups: [],
        garancia: this.$store.state.selectfields.warrantyType,
        garanciaEN: this.$store.state.selectfields.warrantyTypeEN,
        garanciaDE: this.$store.state.selectfields.warrantyTypeDE,
        simLock: this.$store.state.selectfields.simLock,
        simLockEN: this.$store.state.selectfields.simLockEN,
        simLockDE: this.$store.state.selectfields.simLockDE,
        countries: [
          { label: 'Magyarország', countryCode: 'HUN' },
        ],
        countriesEN: [
          { label: 'Hungary', countryCode: 'HUN' },
        ],
        countriesDE: [
          { label: 'Ungarn', countryCode: 'HUN' },
        ],
        postalCodes: [
          { header: this.$t('inputMessages.startTypingPostalCode') },
        ],
        cities: [
          { header: this.$t('inputMessages.startTypingCity') },
        ],
        streets: [
          { header: this.$t('inputMessages.startTypingStreet') },
        ],
        customerShippingAddresses: [],
        customerBillingAddresses: [],
        // számlázási címhez a selectek
        postalCodesInvoice: [
          { header: this.$t('inputMessages.startTypingPostalCode') },
        ],
        citiesInvoice: [
          { header: this.$t('inputMessages.startTypingCity') },
        ],
        streetsInvoice: [
          { header: this.$t('inputMessages.startTypingStreet') },
        ],
      },
      pickUpMinDate: null,
      pickUpMaxDate: null,
      datePickerModal: false,
      pickUpDatePickerModal: false,
    };
  },
  methods: {
    setMaxAvailableDateOfPurchase() {
      const now = new Date();
      const date = dayjs(now).subtract(3, 'day');
      this.maxDate = date.toISOString().substr(0, 10);
    },
    setDefaultCountry() {
      if (this.$i18n.locale === 'hu-HU') {
        this.serviceRequestForm.serviceRequest.warrantyType = this.$store.state.selectfields.warrantyType[0];
        this.serviceRequestForm.deliveryInAddress.country = this.selectFieldOptions.countries[0];
        this.serviceRequestForm.billingAddress.country = this.selectFieldOptions.countries[0];
      } else if (this.$i18n.locale === 'de-DE') {
        this.serviceRequestForm.serviceRequest.warrantyType = this.$store.state.selectfields.warrantyTypeDE[0];
        this.serviceRequestForm.deliveryInAddress.country = this.selectFieldOptions.countriesDE[0];
        this.serviceRequestForm.billingAddress.country = this.selectFieldOptions.countriesDE[0];
      } else {
        this.serviceRequestForm.serviceRequest.warrantyType = this.$store.state.selectfields.warrantyTypeEN[0];
        this.serviceRequestForm.deliveryInAddress.country = this.selectFieldOptions.countriesEN[0];
        this.serviceRequestForm.billingAddress.country = this.selectFieldOptions.countriesEN[0];
      }
      this.serviceRequestForm.serviceRequest.simLock = this.selectFieldOptions.simLock[4];
    },
    async setCustomerDatas() {
      this.serviceRequestForm.customer.firstName = this.$store.state.authModule.user.profile.firstName;
      this.serviceRequestForm.customer.lastName = this.$store.state.authModule.user.profile.lastName;
      this.serviceRequestForm.customer.email = this.$store.state.authModule.user.profile.email;
      this.serviceRequestForm.customer.phoneNumber = this.$store.state.authModule.user.profile.phone;
      this.serviceRequestForm.customer.id = getUid();
    },
    setCustomerAddresses() {
      this.$store.state.authModule.user.addresses.forEach((item, index) => {
        let temp = {};
        let tempName = '';
        if (item.billingName.length > 1) {
          tempName = `${item.billingName} -`;
        }
        temp = {
          id: item.id,
          label: `${tempName} ${item.zipCode} ${item.city}, ${item.publicPlaceName} ${item.publicPlaceType} ${item.houseNumber}`
        };
        if (item.addressType.id === 1) {
          this.selectFieldOptions.customerShippingAddresses.push(temp);
        } else if (item.addressType.id === 2) {
          this.selectFieldOptions.customerBillingAddresses.push(temp);
        }
      });
      this.selectFieldOptions.customerShippingAddresses.push({id: 999999888888, label: 'Új címet adok meg'});
      this.selectFieldOptions.customerBillingAddresses.push({id: 999999888888, label: 'Új címet adok meg'});
    },
    setProcessType() {
      this.serviceRequestForm.processType = 1;
      if (getToken() && getUid()) {
        if (!this.serviceRequestForm.deliveryInAddress.id && !this.serviceRequestForm.billingAddress.id) {
          this.serviceRequestForm.processType = 4;
        } else if (!this.serviceRequestForm.billingAddress.id) {
          this.serviceRequestForm.processType = 3;
        } else if (!this.serviceRequestForm.deliveryInAddress.id) {
          this.serviceRequestForm.processType = 2;
        } else {
          this.serviceRequestForm.processType = 5;
        }
      }
    },
    nextStep() {
      this.VStepper += 1;
    },
    prevStep() {
      this.VStepper -= 1;
    },
    /*
    BEKÜLDÉSHEZ SZÜKSÉGES METÓDUSOK
     */
    async sendContactForm() {
      if(this.documentCheck()) {
        this.openLoadingDialog();
        this.serviceRequestForm.deliveryOutAddress = this.serviceRequestForm.deliveryInAddress;
        this.serviceRequestForm.serviceRequest.mobilProvider = this.serviceRequestForm.serviceRequest.simLock;
        this.setProcessType();
        if (this.serviceRequestForm.accountRegistration === 'no' || this.serviceRequestForm.accountRegistration === null) {
          this.serviceRequestForm.customer.password = null;
        }
        const response = await this.$store.dispatch('sendMobilfutarRequest',
            { form: this.serviceRequestForm, attachment: this.attachment });
        // console.log('sendContactForm response: ', response);
        if (response.status == 200) {
          this.responseDialog.response.error = false;
          this.responseDialog.response.message = `<div class="display-1 mb-6"><b>Sikeres beküldés!</b></div><div class="headline mb-4">Kérése megérkezett hozzánk! A megadott e-mail címre (<b>${this.serviceRequestForm.customer.email}</b>) hamarosan visszaigazoló e-mailt küldünk!</div><div>Köszönjük megkeresését!</div>`;
        } else if (response.status == 201) {
          this.responseDialog.response.error = false;
          this.responseDialog.response.message = `<div class="display-1 mb-6"><b>201!! Sikeres beküldés!</b></div><div class="headline mb-4">Kérése megérkezett hozzánk! A megadott e-mail címre (<b>${this.serviceRequestForm.customer.email}</b>) hamarosan visszaigazoló e-mailt küldünk!</div><div>Köszönjük megkeresését!</div>`;
        } else if(response.status == 405) {
          this.responseDialog.response.error = true;
          this.responseDialog.response.message = '<div class="display-1 mb-6"><b>Váratlan hiba történt!</b><!--<b>Érvénytelen HTTP hívás</b>--></div><div class="headline mb-4">A folyamat során váratlan hiba lépett fel!<br>Dolgozunk a hiba elhárításán!</div><div>Köszönjük türelmét!</div>';
        } else {
          this.responseDialog.response.error = true;
          this.responseDialog.response.message = this.ERROR_MESSAGES.FORM_FAIL_MESSAGE;
        }
        this.controlDialogs();
      } else {
        this.openNoFileDialog();
      }
    },
    openLoadingDialog() {
      this.loadingDialog.visible = true;
    },
    closeLoadingDialog() {
      this.loadingDialog.visible = false;
    },
    setupResponseDialog(message) {
      this.responseDialog.response.message = message;
    },
    openResponseDialog() {
      this.responseDialog.visible = true;
    },
    closeResponseDialog() {
      this.responseDialog.visible = false;
      if (!this.responseDialog.response.error) {
        this.$router.push('/');
      }
    },
    openNoFileDialog() {
      this.noFileDialog.response.message = 'Az egyik dokumentum feltöltése kötelező! Fogalmazzátok meg szépen :)';
      this.noFileDialog.visible = true;
    },
    closeNoFileDialog() {
      this.noFileDialog.visible = false;
    },
    controlDialogs() {
      setTimeout(this.closeLoadingDialog, 3000);
      setTimeout(this.nextStep, 3100);
    },
    /**
     *
     * Ellenörzi, hogy számla vagy garjegy van-e feltöltve
     *
     **/
    documentCheck() {
      let validForm = false;
      if (Array.isArray(this.attachment.invoice) || this.attachment.invoice === null) {
        validForm = !(Array.isArray(this.attachment.warrantyCard) || this.attachment.warrantyCard === null);
      } else {
        validForm = true;
      }
      return validForm;
    },
    /**
     * SZÁLLÍTÁSI CÍM MEGADÁSÁHOZ FGV-EK
     */
    async getPostalCodesByCountry(value) {
      const response = await HEREGETPOSTALCODESBYCOUNTRY(value, this.serviceRequestForm.deliveryInAddress.country.countryCode);
      if (!response.error) {
        this.selectFieldOptions.postalCodes = response.data;
        this.postalCodeSearchIsLoading = false;
        this.selectFieldOptions.postalCodes.unshift({ header: this.$t('inputMessages.startTypingPostalCode') });
      }
    },
    async getCityByPostalCode() {
      let postalcode = 0;
      if (this.serviceRequestForm.deliveryInAddress.postalCode && this.serviceRequestForm.deliveryInAddress.postalCode.address) {
        postalcode = this.serviceRequestForm.deliveryInAddress.postalCode.address.postalCode;
        this.serviceRequestForm.deliveryInAddress.postalCode = postalcode;
      }
      const response = await HEREGETCITYBYPOSTALCODE(this.serviceRequestForm.deliveryInAddress.postalCode, this.serviceRequestForm.deliveryInAddress.country.countryCode);
      if (!response.error) {
        this.selectFieldOptions.cities = response.data;
        this.citySearchIsLoading = false;
        this.selectFieldOptions.cities.unshift({ header: this.$t('inputMessages.startTypingCity') });
      }
    },
    async getStreetByPostalCode(value) {
      const response = await HEREGETSTREETBYPOSTALCODE(value, this.serviceRequestForm.deliveryInAddress.postalCode, this.serviceRequestForm.deliveryInAddress.country.countryCode);
      if (!response.error) {
        this.selectFieldOptions.streets = response.data;
        this.streetSearchIsLoading = false;
        this.selectFieldOptions.streets.unshift({ header: this.$t('inputMessages.startTypingStreet') });
      }
    },
    async onPostalCodeSelect() {
      await this.getCityByPostalCode();
      if (this.selectFieldOptions.cities.length === 2) {
        this.serviceRequestForm.deliveryInAddress.city = this.selectFieldOptions.cities[1].address.city;
        this.citySearch = this.selectFieldOptions.cities[1].address.city;
      }
      this.serviceRequestForm.deliveryInAddress.publicPlaceName = '';
      this.serviceRequestForm.deliveryInAddress.publicPlaceType = '';
      this.serviceRequestForm.deliveryInAddress.streetNumber = '';
      this.streetIdeiglenes = '';
      this.streetSearch = null;
    },
    onPublicPlaceSelect() {
      let street = '';
      let type = '';
      if (this.streetIdeiglenes && this.streetIdeiglenes.length > 0) {
        // console.log('1');
        street = this.streetIdeiglenes.substring(0, this.streetIdeiglenes.lastIndexOf(' '));
        type = this.streetIdeiglenes.substring(this.streetIdeiglenes.lastIndexOf(' '), this.streetIdeiglenes.length + 1).trim();
        if (this.streetIdeiglenes.lastIndexOf(' ') > 0) {
          // console.log('1a');
          this.serviceRequestForm.deliveryInAddress.publicPlaceName = street;
          this.serviceRequestForm.deliveryInAddress.publicPlaceType = type;
        } else {
          // console.log('1b');
          this.serviceRequestForm.deliveryInAddress.publicPlaceName = this.streetIdeiglenes;
          type = this.selectFieldOptions.streets[1].address.street.substring(this.selectFieldOptions.streets[1].address.street.lastIndexOf(' '), this.selectFieldOptions.streets[1].address.street.length + 1).trim();
          this.serviceRequestForm.deliveryInAddress.publicPlaceType = type;
        }
      } else if (this.streetIdeiglenes && this.streetIdeiglenes.address) {
        // console.log('2');
        street = this.streetIdeiglenes.address.street.substring(0, this.streetIdeiglenes.address.street.lastIndexOf(' '));
        type = this.streetIdeiglenes.address.street.substring(this.streetIdeiglenes.address.street.lastIndexOf(' '), this.streetIdeiglenes.address.street.length + 1).trim();
        if (this.streetIdeiglenes.address.street.lastIndexOf(' ') > 0) {
          // console.log('2a');
          this.serviceRequestForm.deliveryInAddress.publicPlaceName = street;
          this.serviceRequestForm.deliveryInAddress.publicPlaceType = type;
        } else {
          console.log('2b');
        }
      }
      //
      //
    },
    preventEnter() {
      //
    },
    clearAllAddressInputs() {
      this.selectFieldOptions.postalCodes = [
        { header: this.$t('inputMessages.startTypingPostalCode') },
      ];
      this.selectFieldOptions.cities = [
        { header: this.$t('inputMessages.startTypingCity') },
      ];
      this.selectFieldOptions.streets = [
        { header: this.$t('inputMessages.startTypingStreet') },
      ];
      this.postalCodeSearch = '';
      this.postalCodeSearchIsLoading = false;
      this.citySearch = '';
      this.citySearchIsLoading = false;
      this.streetSearch = '';
      this.streetSearchIsLoading = false;
      this.streetIdeiglenes = '';
      this.serviceRequestForm.deliveryInAddress.postalCode = '';
      this.serviceRequestForm.deliveryInAddress.city = '';
      this.serviceRequestForm.deliveryInAddress.publicPlaceType = '';
      this.serviceRequestForm.deliveryInAddress.publicPlaceName = '';
      this.serviceRequestForm.deliveryInAddress.streetNumber = '';
    },
    saveDeliveryInAddressData() {
      this.deliveryInAddressString = this.serviceRequestForm.deliveryInAddress.postalCode + ' '
        + this.serviceRequestForm.deliveryInAddress.city + ', '
        + this.serviceRequestForm.deliveryInAddress.publicPlaceName + ' '
        + this.serviceRequestForm.deliveryInAddress.publicPlaceType + ' '
        + this.serviceRequestForm.deliveryInAddress.streetNumber;
      this.deliveryInAddressModal = false;
    },
    /***
     * SZÁMLÁZÁSI CÍM MEGADÁSÁHOZ A FGV-EK
     */
    async getPostalCodesByCountryInvoice(value) {
      const response = await HEREGETPOSTALCODESBYCOUNTRY(value, this.serviceRequestForm.billingAddress.country.countryCode);
      if (!response.error) {
        this.selectFieldOptions.postalCodesInvoice = response.data;
        this.postalCodeSearchIsLoadingInvoice = false;
        this.selectFieldOptions.postalCodesInvoice.unshift({ header: this.$t('inputMessages.startTypingPostalCode') });
      }
    },
    async getCityByPostalCodeInvoice() {
      let postalcode = 0;
      if (this.serviceRequestForm.billingAddress.postalCode && this.serviceRequestForm.billingAddress.postalCode.address) {
        postalcode = this.serviceRequestForm.billingAddress.postalCode.address.postalCode;
        this.serviceRequestForm.billingAddress.postalCode = postalcode;
      }
      const response = await HEREGETCITYBYPOSTALCODE(this.serviceRequestForm.billingAddress.postalCode, this.serviceRequestForm.billingAddress.country.countryCode);
      if (!response.error) {
        this.selectFieldOptions.citiesInvoice = response.data;
        this.citySearchIsLoadingInvoice = false;
        this.selectFieldOptions.citiesInvoice.unshift({ header: this.$t('inputMessages.startTypingCity') });
      }
    },
    async getStreetByPostalCodeInvoice(value) {
      const response = await HEREGETSTREETBYPOSTALCODE(value, this.serviceRequestForm.billingAddress.postalCode, this.serviceRequestForm.billingAddress.country.countryCode);
      if (!response.error) {
        this.selectFieldOptions.streetsInvoice = response.data;
        this.streetSearchIsLoadingInvoice = false;
        this.selectFieldOptions.streetsInvoice.unshift({ header: this.$t('inputMessages.startTypingStreet') });
      }
    },
    async onPostalCodeSelectInvoice() {
      await this.getCityByPostalCodeInvoice();
      if (this.selectFieldOptions.citiesInvoice.length === 2) {
        this.serviceRequestForm.billingAddress.city = this.selectFieldOptions.citiesInvoice[1].address.city;
        this.citySearchInvoice = this.selectFieldOptions.citiesInvoice[1].address.city;
      }
      this.serviceRequestForm.billingAddress.publicPlaceName = '';
      this.serviceRequestForm.billingAddress.publicPlaceType = '';
      this.serviceRequestForm.billingAddress.streetNumber = '';
      this.streetIdeiglenesInvoice = '';
      this.streetSearchInvoice = null;
    },
    onPublicPlaceSelectInvoice() {
      let street = '';
      let type = '';
      if (this.streetIdeiglenesInvoice && this.streetIdeiglenesInvoice.length > 0) {
        street = this.streetIdeiglenesInvoice.substring(0, this.streetIdeiglenesInvoice.lastIndexOf(' '));
        type = this.streetIdeiglenesInvoice.substring(this.streetIdeiglenesInvoice.lastIndexOf(' '), this.streetIdeiglenesInvoice.length + 1).trim();
        if (this.streetIdeiglenesInvoice.lastIndexOf(' ') > 0) {
          // console.log('3a');
          this.serviceRequestForm.billingAddress.publicPlaceName = street;
          this.serviceRequestForm.billingAddress.publicPlaceType = type;
        } else {
          // console.log('3b');
          this.serviceRequestForm.billingAddress.publicPlaceName = this.streetIdeiglenesInvoice;
          type = this.selectFieldOptions.streetsInvoice[1].address.street.substring(this.selectFieldOptions.streetsInvoice[1].address.street.lastIndexOf(' '), this.selectFieldOptions.streetsInvoice[1].address.street.length + 1).trim();
          this.serviceRequestForm.billingAddress.publicPlaceType = type;
        }
      } else if (this.streetIdeiglenesInvoice && this.streetIdeiglenesInvoice.address) {
        street = this.streetIdeiglenesInvoice.address.street.substring(0, this.streetIdeiglenesInvoice.address.street.lastIndexOf(' '));
        type = this.streetIdeiglenesInvoice.address.street.substring(this.streetIdeiglenesInvoice.address.street.lastIndexOf(' '), this.streetIdeiglenesInvoice.address.street.length + 1).trim();
        if (this.streetIdeiglenesInvoice.address.street.lastIndexOf(' ') > 0) {
          // console.log('4a');
          this.serviceRequestForm.billingAddress.publicPlaceName = street;
          this.serviceRequestForm.billingAddress.publicPlaceType = type;
        } else {
          console.log('4b');
        }
      }
    },
    clearAllAddressInputsInvoice() {
      this.selectFieldOptions.postalCodesInvoice = [
        { header: this.$t('inputMessages.startTypingPostalCode') },
      ];
      this.selectFieldOptions.citiesInvoice = [
        { header: this.$t('inputMessages.startTypingCity') },
      ];
      this.selectFieldOptions.streetsInvoice = [
        { header: this.$t('inputMessages.startTypingStreet') },
      ];
      this.postalCodeSearchInvoice = '';
      this.postalCodeSearchIsLoadingInvoice = false;
      this.citySearchInvoice = '';
      this.citySearchIsLoadingInvoice = false;
      this.streetSearchInvoice = '';
      this.streetSearchIsLoadingInvoice = false;
      this.streetIdeiglenesInvoice = '';
      this.serviceRequestForm.billingAddress.postalCode = '';
      this.serviceRequestForm.billingAddress.city = '';
      this.serviceRequestForm.billingAddress.publicPlaceType = '';
      this.serviceRequestForm.billingAddress.publicPlaceName = '';
      this.serviceRequestForm.billingAddress.streetNumber = '';
    },
    saveBillingAddressData() {
      this.billingAddressString = this.serviceRequestForm.billingAddress.billingName
        + ' - ' + this.serviceRequestForm.billingAddress.postalCode + ' '
        + this.serviceRequestForm.billingAddress.city + ', '
        + this.serviceRequestForm.billingAddress.publicPlaceName + ' '
        + this.serviceRequestForm.billingAddress.publicPlaceType + ' '
        + this.serviceRequestForm.billingAddress.streetNumber;
      this.billingAddressModal = false;
    },
    openBillingAddressModal() {
      // a meglévő szállítási címet kimentjük egy ideiglenes változóba,
      // hogy ha a user a Mégsem gombra kattint, akkor megmaradjon az előzőleg beírt cím
      this.billingAddressBackup = this.serviceRequestForm.billingAddress;
      this.billingAddressModal = true;
    },
    closeBillingAddressModalWithoutSave() {
      // Mégsem gombra kattintva visszaszerezzük az előzőleg beírt címet
      this.serviceRequestForm.billingAddress = this.billingAddressBackup;
      this.billingAddressModal = false;
      this.billingAddressBackup = null;
    },
    async getProductGroupsByBrandId() {
      const response = await this.$store.dispatch('getProductGroupsByBrandId', this.serviceRequestForm.serviceRequest.brand.id);
      if (response.status === 200) {
        return response.data;
      } else {
        return [];
      }
    },
  },
  watch: {
    postalCodeSearch(value) {
      this.postalCodeSearchIsLoading = true;
      this.getPostalCodesByCountry(value);
    },
    streetSearch(value) {
      this.streetSearchIsLoading = true;
      this.getStreetByPostalCode(value);
    },
    postalCodeSearchInvoice(value) {
      this.postalCodeSearchIsLoading = true;
      this.getPostalCodesByCountryInvoice(value);
    },
    streetSearchInvoice(value) {
      this.streetSearchIsLoading = true;
      this.getStreetByPostalCodeInvoice(value);
    },
    billingAddressIsEqualdeliveryInAddress(value) {
      if (value) {
        this.serviceRequestForm.billingAddress.billingName = this.serviceRequestForm.customer.lastName + ' ' + this.serviceRequestForm.customer.firstName;
        this.serviceRequestForm.billingAddress.postalCode = this.serviceRequestForm.deliveryInAddress.postalCode;
        this.serviceRequestForm.billingAddress.city = this.serviceRequestForm.deliveryInAddress.city;
        this.serviceRequestForm.billingAddress.publicPlaceName = this.serviceRequestForm.deliveryInAddress.publicPlaceName;
        this.serviceRequestForm.billingAddress.publicPlaceType = this.serviceRequestForm.deliveryInAddress.publicPlaceType;
        this.serviceRequestForm.billingAddress.streetNumber = this.serviceRequestForm.deliveryInAddress.streetNumber;
        this.serviceRequestForm.billingAddress.other = this.serviceRequestForm.deliveryInAddress.other;
        this.saveBillingAddressData();
      } else {
        this.clearAllAddressInputsInvoice();
        this.serviceRequestForm.billingAddress.billingName = '';
        this.serviceRequestForm.billingAddress.taxNumber = '';
        this.serviceRequestForm.billingAddress.other = '';
        this.billingAddressString = '';
      }
    },
    'serviceRequestForm.serviceRequest.productGroup': function (newVal) {
      this.serviceRequestForm.serviceRequest.uniqueIdentifierIn1 = null;
      this.serviceRequestForm.serviceRequest.uniqueIdentifierIn2 = null;
      this.serviceRequestForm.serviceRequest.uniqueIdentifierIn3 = null;
      this.selectedProductGroup = this.selectFieldOptions.productGroups.find(item => item.id === newVal.id);
    },
    'serviceRequestForm.serviceRequest.insuranceNumber': function (newVal) {
      //
    },
    customerShippingAddress(value) {
      // console.log(value);
      if (value.id === 999999888888) {
        this.shippingAddressStepper = 2;
        this.serviceRequestForm.deliveryInAddress.id = null;
      } else {
        this.serviceRequestForm.deliveryInAddress.id = value.id;
        this.deliveryInAddressString = value.label;
        this.deliveryInAddressModal = false;
        this.deliveryInAddressForm = true;
      }
    },
    customerBillingAddress(value) {
      // console.log(value);
      if (value.id === 999999888888) {
        this.billingAddressStepper = 2;
        this.serviceRequestForm.billingAddress.id = null;
      } else {
        this.serviceRequestForm.billingAddress.id = value.id;
        this.billingAddressString = value.label;
        this.billingAddressModal = false;
        this.billingAddressForm = true;
      }
    },
    // TODO van amikor nem létezik a brand.id és akkor hibát dob, ezt ellenőrizni kell mielőtt lefutna a hívás, de amúgy OK
    'serviceRequestForm.serviceRequest.brand': async function (newVal) {
      this.selectFieldOptions.productGroups = [];
      this.selectedProductGroup = null;
      this.selectFieldOptions.productGroups = await this.getProductGroupsByBrandId();
    }
  }
};
</script>

<style scoped>

</style>
